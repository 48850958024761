import request from '@/request'
import Qs from 'qs'
// const H5Url = process.env.NODE_ENV === 'production' ? 'https://jiantuoshop.gotocloud8.net' : 'http://jtsandbox.gotocloud8.net'
// const H5Url = 'https://jiantuoshop.gotocloud8.net'//老商城
const H5UrlA = 'https://healthingcoretest.giantgocloud.com'// 新商城
let H5Report ="https://healthingcoretest.giantgocloud.com"// 报告请求测试地址
// const H5UrlA = 'http://localhost:5000'// 新商城 本地
//  const H5UrlA = 'https://apitest.giantgocloud.com'//新商城测试站
// const H5UrlTest = 'https://jtsandbox.gotocloud8.net'//老商城测试
// const CoreUrl = 'https://api.giantgocloud.com'//healthyingcore
// const CoreUrl = 'http://192.168.110.84:8082'//本地
// export function getInfo(data) {
//   return request({
//     url: '',
//     method: 'get',
//     params: data
//   })
// }
export default H5Report
export function getReportData (data) {
  return request({
    url: `${H5UrlA}/Serv/API/Admin/User/GetUserExpand.ashx`,
    method: 'post',
    data
  })
}
export function getCoreReport (data) {
  return request({
    // https://healthingcore.giantgocloud.com/api/GiantgoSmsUserDetailInfo/GetTotalReport
    url: `${H5UrlA}/api/GiantgoSmsUserDetailInfo/GetTotalReport`,
    method: 'post',
    data
  })
}
// 获取订单详情/查询用户信息/订单新增/问卷列表
export function queryMallApi (data) {
  return request({
    url: `${H5UrlA}/Serv/API/Mall/MallApi.ashx`,
    method: 'post',
    data
  })
}
// 脂联素报告
export function adiponectinReport (data) {
  return request({
    url: `${H5UrlA}/Serv/API/Admin/User/GetUserExpand.ashx`,
    method: 'post',
    data
  })
}

// 线下产品说明书
export function instructionsPdfOffline (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsInstructionsExcelDetailInfo/GetInstructionsInfo`,
    method: 'post',
    data: Qs.stringify(data),
    headers: {
      'content-type': 'application/x-www-form-urlencoded'
    }
  })
}
// 产品说明书
export function GetFormulaInfoByOrder (data) {
  return request({
    url: `${H5UrlA}/api/AgentOrderFormula/GetFormulaInfoByOrder`,
    method: 'post',
    data
  })
}
// 线上产品说明书
export function GetInstructionOnline (data) {
  return request({
    url: `${H5UrlA}/api/InstructionOnline/GetInstructionOnline`,
    method: 'post',
    data
  })
}
// 获取矩阵数据-Pdf
export function GetMatrixList (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsMatrixIndexInfo/GetMatrixList`,
    method: 'post',
    data
  })
}
// 获取矩阵数据
export function GetMatrixDataByUserId (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsMatrixIndexInfo/GetMatrixDataByUserId`,
    method: 'post',
    data
  })
}
// 获取月度健康页面数据
export function GetBraceletFoodInfoByUserId (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsBraceletFoodInfo/GetBraceletFoodInfoByUserId`,
    method: 'post',
    data
  })
}

// 健康问卷信息
export function GetHealthQuestionList (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsFoodQuestionInfo/GetHealthQuestionList`,
    method: 'post',
    data
  })
}
// 新增问卷用户信息
export function CustomerFoodResult (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsFoodQuestionInfo/CustomerFoodResult`,
    method: 'post',
    data
  })
}
// 食疗方案
export function CustomerFoodWayResult (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsFoodQuestionInfo/CustomerFoodWayResult`,
    method: 'post',
    data
  })
}
// 查询食疗方案
export function GetFoodWayListById (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsFoodQuestionInfo/GetFoodWayListById`,
    method: 'post',
    data
  })
}

// 获取用户信息
export function GetUserInfo (data) {
  return request({
    url: `${H5UrlA}/api/User/GetUserInfo`,
    method: 'post',
    data
  })
}

// 更新用户信息
export function CompleteUserInfo (data) {
  return request({
    url: `${H5UrlA}/api/User/CompleteUserInfo`,
    method: 'post',
    data
  })
}

// 校验拍照时效
export function GetDetailDataByUserId (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsUserDetailInfo/GetDetailDataByUserId`,
    method: 'post',
    data
  })
}

// 校验拍照时效
export function GetCoreReportListData (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsUserDetailInfo/GetCoreReportListData`,
    method: 'post',
    data
  })
}
//
export function InsertUserDetail (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsUserDetailInfo/InsertUserDetail`,
    method: 'post',
    data
  })
}
// 小程序个人中心问卷列表
export function GetQuestionListDataByUserId (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsFoodQuestionInfo/GetQuestionListDataByUserId`,
    method: 'post',
    data
  })
}

// healingCoreIfeel报告数据
export function getHealingIfeelReport (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsUserDetailInfo/GetIFeelReportInfoById`,
    method: 'post',
    data
  })
}

// 点击我的健康go按钮根据数据看是否可以进行跳转
export function IsClickMore (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsUserDetailInfo/IsClickMore`,
    method: 'post',
    data
  })
}

// 获取我的健康页面的问卷调查列表
export function GetHealQuestionInfo (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsUserDetailInfo/GetHealQuestionInfo`,
    method: 'post',
    data
  })
}
// 我的疗愈计划
export function GetHealingPlanInfo (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsUserDetailInfo/GetHealingPlanInfo`,
    method: 'post',
    data
  })
}
// 我的健康页面数据
export function GetHealthPageInfo (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsUserDetailInfo/GetHealthPageInfo`,
    method: 'post',
    data
  })
}
// 回填问卷信息
export function ReLoadQuestion (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsUserDetailInfo/ReLoadQuestion`,
    method: 'post',
    data
  })
}
// 判断该用户上个月是否填写过问卷
export function IsQuestionByUserId (data) {
  return request({
    url: `${H5UrlA}/api/GiantgoSmsUserDetailInfo/IsQuestionByUserId`,
    method: 'post',
    data
  })
}

// 获取手环数据-按日期
export function GetWatchTypeByDate (data) {
  return request({
    url: `${H5UrlA}/api/SportsWatch/GetWatchTypeByDate`,
    method: 'post',
    data
  })
}

// 获取手环历史数据
export function GetWatchTypeByHistoryDate (data) {
  return request({
    url: `${H5UrlA}/api/SportsWatch/GetWatchTypeByHistoryDate`,
    method: 'post',
    data
  })
}

// 获取
export function GetInsertReportUser (data) {
  return request({
    url: `${H5UrlA}/api/SportsWatch/GetWatchTypeByHistoryDate`,
    method: 'post',
    data
  })
}
// 获取器官列表
export function GetReportOrganBySex (data) {
  return request({
    url: `${H5UrlA}/api/GiantgosmsReportOrganInfo/GetReportOrganBySex`,
    method: 'post',
    data
  })
}
// 判断检测编号是否存在
export function GetDataValueIsExist (data) {
  return request({
    url: `${H5UrlA}/api/GiantgosmsReportOrganInfo/DataValueIsExist`,
    method: 'post',
    data
  })
}
// 新增线下待检测用户
export function GetInsertReportUserA (data) {
  return request({
    url: `${H5UrlA}/api/GiantgosmsReportOrganInfo/InsertReportUser`,
    method: 'post',
    data
  })
}

export function GetPersonHomeData(data) {
  return request({
    url: `${H5Report}/boardApi/DashBoard/GetPersonHomeData`,
    method: 'post',
    data
  })
}

export function GetUserOrganHealingInfo(data) {
  return request({
    url: `${H5Report}/boardApi/DashBoard/GetUserOrganHealingInfo`,
    method: 'post',
    data
  })
}
export function GetUserOrganHealingChartData(data) {
  return request({
    url: `${H5Report}/boardApi/DashBoard/GetUserOrganHealingChartData`,
    method: 'post',
    data
  })
}
export function GetOrganDetail(data) {
  return request({
    url: `${H5Report}/boardApi/DashBoard/GetOrganDetail`,
    method: 'post',
    data
  })
}

export function GetOrganSickTrend(data) {
  return request({
    url: `${H5Report}/boardApi/DashBoard/GetOrganSickTrend`,
    method: 'post',
    data
  })
}


export function GetSickDetail(data) {
  return request({
    url: `${H5Report}/boardApi/DashBoard/GetSickDetail`,
    method: 'post',
    data
  })
}

export function GetIndicatorDetail(data) {
  return request({
    url: `${H5Report}/boardApi/DashBoard/GetIndicatorDetail`,
    method: 'post',
    data
  })
}

export function GetSignatureByCustomerCode(data) {
  return request({
    url: `${H5Report}/boardApi/DashBoard/GetSignatureByCustomerCode`,
    method: 'post',
    data
  })
}

export function GetPhysiologyInfo(data) {
  return request({
    url: `${H5Report}/boardApi/DashBoard/GetPhysiologyInfo`,
    method: 'post',
    data
  })
}

export function GetHealthManagementPlan(data) {
  return request({
    url: `${H5Report}/boardApi/DashBoard/GetHealthManagementPlan`,
    method: 'post',
    data
  })
}

export function GetSuggestionsByIndicators(data) {
  return request({
    url: `${H5Report}/boardApi/DashBoard/GetSuggestionsByIndicators`,
    method: 'post',
    data
  })
}
export function GetUserIndicatorsSuggestions(data) {
  return request({
    url: `${H5Report}/boardApi/DashBoard/GetUserIndicatorsSuggestions`,
    method: 'post',
    data
  })
}

export function GetCustomerApiList(data) {
  return request({
    url: `${H5Report}/boardApi/BoardCustomer/GetCustomerApiList`,
    method: 'post',
    data
  })
}
