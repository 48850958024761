import axios from 'axios'
import store from '@/store'
import Vue from "vue"
//实例化
var vm = new Vue()


// create an axios instance
const service = axios.create({
  // baseURL: Url,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000, // request timeout
})


// request interceptor
service.interceptors.request.use(
  config => {
    // if (store.state.token) {
    //   config.headers['Authorization'] = `Bearer ${getToken()}`
    // }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor 
service.interceptors.response.use(
  response => {
    // console.log('resssss',response.data);
    
    const res = response.data
    if(res.code == 200 || res.status || res.isSuccess||res.code == 0||res.code == 1||res) {
        return res
    } else {
        // vm.$toast(res.msg || res.errmsg || '无数据')
        return Promise.reject(res.msg || res.errmsg)
    }
  },
  error => {
    vm.$toast('请求失败')
    return Promise.reject(error)
  }
)

export default service
